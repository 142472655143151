<template>
  <div id="app" class="print-template">
    <div id="header">
      <div class="logo">{{ $t('page.company') }}</div>
      <div class="claim">{{ $t('page.claim') }}</div>
    </div>
        <div id="main">
      <div class="table-wrapper border">
        <table id="main1">
          <tr>
            <td class="border">{{ $t('print.company.value') }} | {{ $t('print.website') }}</td>
            <td>{{ date }}</td>
          </tr>
        </table>
      </div>
      <div class="table-wrapper border">
        <table id="main2">
          <tr class="row1 calc1" v-if="calc === 'cabin'">
            <td class="col1 label">{{ $t('result.kb.label') }}</td>
            <td class="col2 label">{{ $t('result.kt.label') }}</td>
            <td class="col3 label">{{ $t('result.tb.label') }}</td>
            <td class="col4 label">{{ $t('print.door') }}</td>
          </tr>
          <tr class="row2 calc1" v-if="calc === 'cabin'">
            <td class="col1 value"><span>{{ kb }}</span><span class="unit">{{ $t('result.kb.unit') }}</span></td>
            <td class="col2 value"><span>{{ kt }}</span><span class="unit">{{ $t('result.kt.unit') }}</span></td>
            <td class="col3 value"><span>{{ tb }}</span><span class="unit">{{ $t('result.tb.unit') }}</span></td>
            <td class="col4 value"><span>{{ doorname }}</span><span class="unit">{{ doordescription }}</span></td>
          </tr>
          <tr class="row1 calc2" v-if="calc === 'shaft'">
            <td class="col1 label">{{ $t('result.sb.label') }}</td>
            <td class="col2 label">{{ $t('result.st.label') }}</td>
            <td class="col3 label">{{ $t('result.tb.label') }}</td>
            <td class="col4 label">{{ $t('print.door') }}</td>
          </tr>
          <tr class="row2 calc2" v-if="calc === 'shaft'">
            <td class="col1 value"><span>{{ sb }}</span><span class="unit">{{ $t('result.sb.unit') }}</span></td>
            <td class="col2 value"><span>{{ st }}</span><span class="unit">{{ $t('result.st.unit') }}</span></td>
            <td class="col3 value"><span>{{ tb }}</span><span class="unit">{{ $t('result.tb.unit') }}</span></td>
            <td class="col4 value"><span>{{ doorname }}</span><span class="unit">{{ doordescription }}</span></td>
          </tr>
        </table>
      </div>
      <div class="table-wrapper border">
        <table id="main3">
          <tr class="row1">
            <td rowspan="7" class="col1"><img v-bind:src="img" width="164" height="140" id="car-img" /></td>
            <td class="col2"></td>
            <td class="col3"></td>
            <td class="col4"></td>
          </tr>
          <tr class="row2">
            <!--<td class="col1"></td>-->
            <td class="col2 label">{{ $t('result.nl.label') }}</td>
            <td rowspan="2" class="col3"><img src="/assets/print/person.png" width="19" height="42" /></td>
            <td class="col4 label">{{ $t('result.pe.unit') }}</td>
          </tr>
          <tr class="row3">
            <!--<td class="col1"></td>-->
            <td class="col2 value"><span>{{ nl }}</span><span class="unit">{{ $t('result.nl.unit') }}</span></td>
            <!--<td class="col3"></td>-->
            <td class="col4 value"><span>{{ pe }}</span></td>
          </tr>
          <tr class="row4">
            <!--<td class="col1"></td>-->
            <td class="col2"></td>
            <td class="col3"></td>
            <td class="col4"></td>
          </tr>
          <tr class="row5 calc1" v-if="calc === 'cabin'">
            <!--<td class="col1"></td>-->
            <td class="col2 label" v-html="$t('result.sb.label')"></td>
            <td colspan="2" class="col32 label" v-html="$t('result.st.label')"></td>
          </tr>
          <tr class="row6 calc1" v-if="calc === 'cabin'">
            <!--<td class="col1"></td>-->
            <td class="col2 value"><span>{{ sb }}</span><span class="unit">{{ $t('result.sb.unit') }}</span></td>
            <td colspan="2" class="col32 value"><span>{{ st }}</span><span class="unit">{{ $t('result.st.unit') }}</span></td>
          </tr>
          <tr class="row5 calc2" v-if="calc === 'shaft'">
            <!--<td class="col1"></td>-->
            <td class="col2 label">{{ $t('result.kb.label') }}</td>
            <td colspan="2" class="col32 label">{{ $t('result.kt.label') }}</td>
          </tr>
          <tr class="row6 calc2" v-if="calc === 'shaft'">
            <!--<td class="col1"></td>-->
            <td class="col2 value"><span>{{ kb }}</span><span class="unit">{{ $t('result.kb.unit') }}</span></td>
            <td colspan="2" class="col32 value"><span>{{ kt }}</span><span class="unit">{{ $t('result.kt.unit') }}</span></td>
          </tr>
          <tr class="row7">
            <!--<td class="col1"></td>-->
            <td class="col2"></td>
            <td class="col3"></td>
            <td class="col4"></td>
          </tr>
        </table>
      </div>
      <div class="table-wrapper border">
        <table id="main4">
          <tr class="row1">
            <td class="col1 label">{{ $t('result.wa') }}</td>
            <td class="col2 label">{{ $t('result.wa1.label') }}</td>
            <td class="col3 label">{{ $t('result.wa2.label') }}</td>
            <td class="col4 label border">{{ $t('result.wa3.label') }}</td>
            <td class="col5 label">{{ $t('result.sg.label') }}</td>
            <td class="col6 label">{{ $t('result.sk.label') }}</td>
          </tr>
          <tr class="row1">
            <td class="col1"></td>
            <td class="col2 value"><span>{{ wa1 }}</span><span class="unit">{{ $t('result.wa1.unit') }}</span></td>
            <td class="col3 value"><span>{{ wa2 }}</span><span class="unit">{{ $t('result.wa2.unit') }}</span></td>
            <td class="col4 value border"><span>{{ wa3 }}</span><span class="unit">{{ $t('result.wa3.unit') }}</span></td>
            <td class="col5 value"><span>{{ sg }}</span><span class="unit">{{ $t('result.sg.unit') }}</span></td>
            <td class="col6 value"><span>{{ sk }}</span><span class="unit">{{ $t('result.sk.unit') }}</span></td>
          </tr>
        </table>
      </div>
      <div class="table-wrapper border">
        <table id="main5">
          <tr class="row1">
            <td class="col1 label border">{{ seLabel }}</td>
            <td class="col2 label border">{{ $t('inputs.dl.label') }}</td>
            <td class="col3 label border">{{ $t('inputs.fh.label') }}</td>
            <td class="col4 label border">{{ $t('inputs.tn.label') }}</td>
            <td class="col5 label">{{ $t('inputs.ge.label') }}</td>
          </tr>
          <tr class="row1">
            <td class="col1 value border">{{ se }}</td>
            <td class="col2 value border">{{ dl }}</td>
            <td class="col3 value border">{{ fh }}</td>
            <td class="col4 value border">{{ tn }}</td>
            <td class="col5 value">{{ ge }}</td>
          </tr>
        </table>
      </div>
      <div class="table-wrapper">
        <table id="main6">
          <tr>
            <td colspan="2" class="col12 label">{{ $t('print.instructions') }}</td>
          </tr>
          <tr v-for="(msg, key) in msgs" v-bind:key="key">
            <td colspan="2" class="col12 text">{{ msg }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div id="footer">
      <table id="address">
        <tr class="row1">
          <td class="col1">{{ $t('print.company.label') }}</td>
          <td class="col2">{{ $t('print.company.value') }}</td>
          <td class="col3">{{ $t('print.telephone.label') }}</td>
          <td class="col4">{{ $t('print.telephone.value') }}</td>
        </tr>
        <tr class="row2">
          <td class="col1">{{ $t('print.street.label') }}</td>
          <td class="col2">{{ $t('print.street.value') }}</td>
          <td class="col3">{{ $t('print.email.label') }}</td>
          <td class="col4">{{ $t('print.email.value') }}</td>
        </tr>
        <tr class="row3">
          <td class="col1">{{ $t('print.city.label') }}</td>
          <td class="col2">{{ $t('print.city.value') }}</td>
          <td class="col3">{{ $t('print.internet.label') }}</td>
          <td class="col4">{{ $t('print.internet.value') }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import fn from '../../services/GlobalService'
import _forEach from 'lodash/forEach'

export default {
  name: 'print-template',
  data () {
    return {}
  },
  computed: {
    locale () {
      return this.get('locale')
    },
    calc () {
      return this.get('calc')
    },
    doorname () {
      return this.$t('doors.' + this.get('door') + '.name')
    },
    doordescription () {
      return this.$t('doors.' + this.get('door') + '.description')
    },
    date () {
      var date
      date = new Date()
      return date.toLocaleDateString(this.locale)
    },
    kb () {
      return this.get('kb', true)
    },
    kt () {
      return this.get('kt', true)
    },
    tb () {
      return this.get('tb', true)
    },
    nl () {
      return this.get('nl', true)
    },
    pe () {
      return this.get('pe')
    },
    sb () {
      return this.get('sb', true)
    },
    st () {
      return this.get('st', true)
    },
    wa1 () {
      return this.get('wa1', true)
    },
    wa2 () {
      return this.get('wa2', true)
    },
    wa3 () {
      return this.get('wa3', true)
    },
    sg () {
      return this.get('sg', true)
    },
    sk () {
      return this.get('sk', true)
    },
    dl () {
      return this.$t('inputs.dl.value_' + this.get('dl'))
    },
    fh () {
      return this.$t('inputs.fh.value_' + this.get('fh'))
    },
    tn () {
      return this.$t('inputs.tn.value_' + this.get('tn'))
    },
    ge () {
      return this.$t('inputs.ge.value_' + this.get('ge'))
    },
    se () {
      var val1, val2, val3
      val1 = this.get('se1', true)
      val2 = this.get('se2', true)
      val3 = this.get('se3', true)
      if (this.calc === 'cabin') {
        if (val1 && val2 && val3) {
          return this.$t('inputs.se_cabin.value_set', { kb: val1, kt: val2, load: val3 })
        }
        return this.$t('inputs.se_cabin.value_none')
      } else {
        if (val1 && val2 && val3) {
          return this.$t('inputs.se_shaft.value_set', { sb: val1, st: val2, load: val3 })
        }
        return this.$t('inputs.se_shaft.value_none')
      }
    },
    seLabel () {
      if (this.calc === 'cabin') {
        return this.$t('inputs.se_cabin.label.print')
      } else {
        return this.$t('inputs.se_shaft.label.print')
      }
    },
    msgs () {
      var param, msgs, msg
      param = this.get('msgs')
      msgs = []
      _forEach(param.split(','), (str) => {
        msg = str.split('-')
        msgs.push(this.$t(
          'msg.' + msg[0], {
            param1: (msg[1] || ''),
            param2: (msg[2] || '')
          }
        ))
      })
      return msgs
    },
    img () {
      var src
      src = '/assets/print/' + this.get('door')
      if (this.get('dl') === '1') {
        src = src + '-dl'
      }
      if (this.get('tn') === '1') {
        src = src + '-tn'
      }
      return src + '.png'
    }
  },
  methods: {
    get (key, formatNumber) {
      var number
      if (this.$route.query[key] !== undefined) {
        if (formatNumber) {
          number = parseFloat(this.$route.query[key])
          return number.toLocaleString(this.locale)
        }
        return this.$route.query[key]
      }
      return ''
    }
  }
}
</script>

<style lang="sass">

// It's important, that everything ist nested under .print-template, because these styles are
// loaded in ONE stylesheet with all the other styles and will have side effects.
// This happens only when compiling, not in development mode.
.print-template
  position: relative
  margin: 10px
  padding: 0
  width: 630px
  height: 950px
  text-align: left
  background: #ffffff

  table
    border-collapse: collapse
    table-layout: auto
    margin: 0
    padding: 0

  tr,
  td
    margin: 0
    padding: 0
    vertical-align: top
    font-family: "tketyperegular", Arial, sans-serif

  #header
    position: relative
    left: 0
    top: 0
    height: 110px
    margin-bottom: 80px
    background-color: $primary

    .logo
      position: absolute
      display: block
      top: 15px
      left: 20px
      width: 120px
      height: 80px
      color: $primary-invert
      text-indent: -9999em
      overflow: hidden
      background: url(/assets/logos/tke_logo_desktop.svg) no-repeat

    .claim
      position: absolute
      display: block
      bottom: 14px
      left: 170px
      width: 300px
      height: 18px
      color: $primary-invert
      text-indent: -9999rem
      overflow: hidden
      background: url(/assets/logos/tke_claim_desktop.svg) no-repeat

  #main
    position: absolute
    left: 0
    top: 190px
    height: 680px

  #footer
    position: absolute
    left: 0
    top: 870px
    height: 80px

    h1
      position: absolute
      top: 20px
      left: 0
      font: 16px/16px "tketypemedium", Arial, sans-serif
      font-weight: normal

  #logo
    position: absolute
    top: 0
    right: 0

  #logo-next-level
    position: absolute
    top: 60px
    left: 0

  #main

    .table-wrapper
      padding: 4px 0

      &.border
        border-bottom: 2px solid #cccccc

    table
      width: 630px

      td
        padding: 0 0 0 8px

        span

          &.unit
            padding-left: 4px
            font: 10px/10px "tketyperegular", Arial, sans-serif
            color: #c9cbc7
            text-transform: uppercase

        &.border
          border-right: 2px solid #c9cbc7

        &.label
          padding-top: 8px
          font: 10px/10px "tketyperegular", Arial, sans-serif
          color: #c9cbc7
          text-transform: uppercase

        &.unit
          padding-top: 4px
          padding-bottom: 8px
          padding-left: 4px
          font: 10px/10px "tketyperegular", Arial, sans-serif
          color: #c9cbc7
          text-transform: uppercase
          vertical-align: bottom

        &.value
          padding-top: 4px
          padding-bottom: 8px
          font: 32px/32px "tketyperegular", Arial, sans-serif
          color: #004B7C
          vertical-align: bottom

    #main1

      td
        padding-top: 8px
        padding-bottom: 2px
        font: 12px/12px "tketypemedium", Arial, sans-serif
        color: #004B7C

      #str-date
        width: 80px

    #main2

      .col1
        width: 130px

      .col2
        width: 130px

      .col3
        width: 130px

      .col4

        span

          &.unit
            display: inline-block
            zoom: 1
            width: 124px

    #main3

      td
        background-color: #eeeeee

        &.value
          font-size: 40px
          line-height: 40px

        &.col1
          width: 300px

        &.col2
          width: 150px

        &.col3
          width: 20px

        &.col4
          width: 130px

      tr

        &.row1

          td
            height: 50px

            &.col1
              vertical-align: middle
              text-align: center

        &.row2

          td

            &.col3
              vertical-align: bottom
              padding-bottom: 10px

        &.row4

          td
            height: 20px

        &.row7

          td
            height: 50px

    #main5

      td

        &.value
          padding-bottom: 4px
          font-size: 16px
          line-height: 16px
          vertical-align: top

        &.col1
          width: 220px

    #main6
      border: none

      td

        &.label
          padding-bottom: 5px

        &.col1
          width: 7px

        &.col2
          padding-right: 20px

        &.text
          padding-top: 5px
          font: 12px/14px "tketyperegular", Arial, sans-serif
          color: #004B7C
          vertical-align: top

  #address
    position: absolute
    left: 0
    bottom: 0
    width: 630px

    td
      padding: 3px 0 0 0
      font: 12px/12px "tketyperegular", Arial, sans-serif

      &.col1
        width: 105px

      &.col2
        width: 210px

      &.col3
        width: 105px
</style>
